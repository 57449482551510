import { httpBearerAuth, httpCodeAuth, processError } from 'utils/requests'
import {
  DateTimeProps,
  LocationProps,
  NotificationProps,
  ResponseProps,
  StudentProps,
  UpdateStudentProps,
  VehicleProps,
} from 'utils/types'

export const enabledRelationships: string[] = ['Parent', 'Grandparent', 'Guardian', 'Other']

export const registerStudent = async (
  schoolName: string,
  code: string,
  userRelationship: string,
  scheduleGroupName: string,
  pickupLocation: LocationProps,
  dropoffLocation: LocationProps,
): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('post', `/api/registerStudent`, schoolName, {
      code,
      userRelationship,
      scheduleGroupName,
      pickupLocation,
      dropoffLocation,
    })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateStudents = async (students: UpdateStudentProps[], sms: string): Promise<ResponseProps> => {
  try {
    const body = { students, sms }
    const { data } = await httpCodeAuth('put', `/api/students`, body, 30000)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateStudentSettings = async (
  schoolName: string,
  studentId: string,
  userName?: string,
  userRelationship?: string,
  studentName?: string,
  scheduleGroupName?: string,
  notificationSettings?: NotificationProps,
): Promise<ResponseProps> => {
  try {
    const body = {
      ...(userName ? { userName } : {}),
      ...(userRelationship ? { userRelationship } : {}),
      ...(scheduleGroupName ? { scheduleGroupName } : {}),
      // check for non-null since falsy values are allowed
      ...(studentName != null ? { studentName } : {}),
      ...(notificationSettings != null ? { notificationSettings } : {}),
    }
    const { data } = await httpBearerAuth('patch', `/api/students/${studentId}/settings`, schoolName, body)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateStudentMorningSettings = async (
  schoolName: string,
  studentId: string,
  pickupLocation: LocationProps,
  pickupVehicle?: VehicleProps,
  pickupSeatsFilled?: number | null,
  pickupSeatsAvailable?: number | null,
  pickupComments?: string,
): Promise<ResponseProps> => {
  try {
    await httpBearerAuth('patch', `/api/students/${studentId}/settings`, schoolName, {
      pickupLocation,
      pickupVehicle,
      ...(pickupSeatsFilled != null ? { pickupSeatsFilled } : {}),
      ...(pickupSeatsAvailable != null ? { pickupSeatsAvailable } : {}),
      pickupComments,
    })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateStudentAfternoonSettings = async (
  schoolName: string,
  studentId: string,
  dropoffLocation: LocationProps,
  dropoffVehicle?: VehicleProps,
  dropoffSeatsFilled?: number | null,
  dropoffSeatsAvailable?: number | null,
  dropoffComments?: string,
  studentActivities?: string[],
  dropoffTimeDefault?: number | null,
  dropoffTimes?: DateTimeProps,
): Promise<ResponseProps> => {
  try {
    await httpBearerAuth('patch', `/api/students/${studentId}/settings`, schoolName, {
      dropoffLocation,
      dropoffVehicle,
      ...(dropoffSeatsFilled != null ? { dropoffSeatsFilled } : {}),
      ...(dropoffSeatsAvailable != null ? { dropoffSeatsAvailable } : {}),
      dropoffComments,
      studentActivities,
      dropoffTimeDefault,
      dropoffTimes,
    })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateStudentAfternoonScheduleSettings = async (
  schoolName: string,
  studentId: string,
  dropoffTimes?: DateTimeProps,
): Promise<ResponseProps> => {
  try {
    const body = { dropoffTimes }
    const { data } = await httpBearerAuth('patch', `/api/students/${studentId}/schedule`, schoolName, body)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateStudentMessages = async (
  schoolName: string,
  studentId: string,
  toStudentId: string,
  poolDate?: string,
): Promise<ResponseProps> => {
  try {
    const body = { toStudentId, poolDate }
    const { data } = await httpBearerAuth('patch', `/api/students/${studentId}/messages`, schoolName, body)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const deleteStudents = async (students: string[]): Promise<ResponseProps> => {
  try {
    const body = { students }
    const { data } = await httpCodeAuth('delete', `/api/students`, body, 30000)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const deleteStudent = async (schoolName: string, studentId: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('delete', `/api/students/${studentId}`, schoolName)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getStudent = async (schoolName: string, studentId: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/students/${studentId}`, schoolName)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getStudents = async (deviceId?: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/students`)
    if (deviceId) {
      data?.students.forEach((v: StudentProps) => {
        const ns: NotificationProps = v.notificationSettings ?? {}
        if (!ns?.devices || !ns.devices.includes(deviceId)) {
          ns.devices = [deviceId]
          updateStudentSettings(v.schoolName, v.studentId, undefined, undefined, undefined, undefined, ns)
        }
      })
    }
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}
