import { Box } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { DefaultProfileIcon } from 'components/icons'
import InputMobileComponent from 'components/input-mobile'
import SelectMobileComponent from 'components/select-mobile'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { parsePhoneNumber } from 'libphonenumber-js/max'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { isNoProfileImage, parseJwt } from 'utils/constants'
import { enabledRelationships, updateStudentSettings } from 'utils/students'
import { MessageProps, StudentProps, UserProps } from 'utils/types'
import { updateUser } from 'utils/users'
import { isValidName, isValidRelationship } from 'utils/validations'
import './index.css'

interface Props {
  tokens: any
  students: StudentProps[]
  user?: Pick<UserProps, 'name' | 'relation'>
  onUpdate: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Tokens, Students, User Relation, and Callback
  const { tokens, students, user, onUpdate } = props ?? {}
  const { IdToken } = tokens
  const phoneNumber: string = parseJwt(IdToken).phone_number

  // states
  const [userFirstName, setUserFirstName] = useState<string>()
  const [userLastName, setUserLastName] = useState<string>()
  const [userStudentRelationship, setUserStudentRelationship] = useState<string>()
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // handlers
  const handleCancel = () => navigate('/')
  const handleSave = async () => {
    try {
      setIsLoading('Updating user profile...')

      const userName: string = `${userFirstName} ${userLastName}`
      handleNameUpdate(userName)

      // Update User Name
      const res1 = await updateUser(phoneNumber, userName)
      if (res1?.error) throw new Error('User name update failed')

      // Update User-Student Relationships
      const res2 = await Promise.all(
        students.map(async (s: StudentProps) => {
          const { schoolName, studentId } = s
          return await updateStudentSettings(schoolName, studentId, userName, userStudentRelationship)
        }),
      )

      res2.forEach((v: any) => {
        if (v?.error) throw new Error('User relationship update failed')
      })
      setMsg({ style: 'success', text: 'Profile updated successfully.' })
    } catch (error) {
      setMsg({
        style: 'error',
        heading: 'Oops, Something Went Wrong',
        text: `We encountered an issue while processing your request. Please try your operation again. If the issue persists, please don't hesitate to contact our support team at support@carpool.school. We'll be happy to assist you further. Thank you for your understanding.`,
      })
    } finally {
      setIsLoading('')
      onUpdate()
    }
  }
  const handleNameUpdate = (name?: string) => {
    // Split the full name into parts based on whitespace
    const parts = name?.trim().split(/\s+/) ?? []
    // Extract the last part as lastName
    const lastName = parts.pop() || ''
    // Join the remaining parts as firstName
    const firstName = parts.join(' ')
    setUserFirstName(firstName)
    setUserLastName(lastName)
  }

  // hooks
  useEffect(() => {
    handleNameUpdate(user?.name ?? '')
    setUserStudentRelationship(user?.relation ?? '')
  }, [user])

  if (!user) return <LinearProgress />
  const isProfileValid: boolean = isValidName(userFirstName, true) && isValidName(userLastName, true)

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Display profile settings *****************/}
      <WizardComponent
        title={'User Profile'}
        onClick={handleSave}
        onBack={handleCancel}
        onCancel={handleCancel}
        btnText={'Save'}
        disable={!isProfileValid}
      >
        <div className='mt-1'>
          <p className='text-gray-400 font-semibold text-bas mb-3.5 mt-4'>Profile Image</p>
          <div className='flex items-center justify-center w-14 h-14 rounded-full border  border-gray-200 bg-gray-100 relative'>
            <DefaultProfileIcon />
            {!isNoProfileImage && (
              <div className='absolute top-9 left-9 w-5 h-5 p-0.5 rounded-full bg-yellow-200'>
                <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20' fill='none'>
                  <path
                    d='M11.7156 7.51667L12.4822 8.28333L4.93223 15.8333H4.16557V15.0667L11.7156 7.51667ZM14.7156 2.5C14.5072 2.5 14.2906 2.58333 14.1322 2.74167L12.6072 4.26667L15.7322 7.39167L17.2572 5.86667C17.5822 5.54167 17.5822 5.01667 17.2572 4.69167L15.3072 2.74167C15.1406 2.575 14.9322 2.5 14.7156 2.5ZM11.7156 5.15833L2.4989 14.375V17.5H5.6239L14.8406 8.28333L11.7156 5.15833Z'
                    fill='#474747'
                  />
                </svg>
              </div>
            )}
          </div>
          <p className='text-gray-400 font-semibold text-base mt-5'>Basic Info</p>
          <Box display='flex' flexDirection='column' justifyContent='center' paddingTop={2} gap={2}>
            <InputMobileComponent
              type='text'
              labeltext='First Name'
              data_testid='first_name_testid'
              isRequired={true}
              isError={!isValidName(userFirstName)}
              value={userFirstName}
              onChange={(v: string) => setUserFirstName(v)}
            />
            <InputMobileComponent
              type='text'
              labeltext='Last Name'
              data_testid='last_name_testid'
              isRequired={true}
              isError={!isValidName(userLastName)}
              value={userLastName}
              onChange={(v: string) => setUserLastName(v)}
            />
            <InputMobileComponent
              labeltext='Phone Number'
              type='tel'
              isReadOnly={true}
              onChange={(v: string) => v}
              value={parsePhoneNumber(phoneNumber).formatNational()}
              isDisabled={true}
              data_testid='Signin-Phone-number-testid'
            />
            {userStudentRelationship && (
              <>
                <p className='text-gray-400 font-semibold text-base mt-3'>Relation Info</p>
                <SelectMobileComponent
                  label='Relationship'
                  data_testid='relationship_testid'
                  isRequired={true}
                  isError={!isValidRelationship(userStudentRelationship)}
                  options={enabledRelationships}
                  value={userStudentRelationship}
                  onChange={(v: string) => setUserStudentRelationship(v)}
                  profile={true}
                />
              </>
            )}
          </Box>
        </div>
      </WizardComponent>
    </>
  )
}

export default Index
