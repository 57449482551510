import AddIcon from '@mui/icons-material/Add'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import KeyIcon from '@mui/icons-material/Key'
import RemoveIcon from '@mui/icons-material/Remove'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import {
  BillingDisabledComponent,
  BillingMaxLimitComponent,
  BillingPaymentComponent,
} from 'components/billing-settings'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import InputComponent from 'components/input'
import ModalConfirmComponent from 'components/modal-confirm'
import NavComponent from 'components/nav'
import PaperComponent from 'components/paper'
import RadioComponent from 'components/radio'
import SwitchComponent from 'components/switch'
import CircularProgressWithLabel from 'components/table/CircularProgressLabel'
import TagComponent from 'components/tag'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import { useEffect, useState } from 'react'
import {
  MAX_SCHEDULE_GROUPS,
  MAX_SCHOOL_ADMINS,
  MIN_SCHEDULE_GROUPS,
  MIN_SCHOOL_ADMINS,
  baseURL,
  getTimeNumberFromString,
  getTimeStringFromNumber,
  isBillingDisabledForSchool,
  schoolSubscriptionLabel,
  titleCaseSchoolNameForUI,
} from 'utils/constants'
import { updateSchool } from 'utils/schools'
import { MessageProps, ScheduleGroupProps, SubscriptionProps } from 'utils/types'
import { isValidEmail, isValidSchoolCode } from 'utils/validations'
import './index.css'

function Index(props: any): JSX.Element {
  /* eslint @typescript-eslint/no-var-requires: "off" */
  const cloneDeep = require('lodash.clonedeep')
  const { schoolUsers } = props ?? {}
  const { schoolName, isCarpoolActive, schoolLocation, students } = schoolUsers ?? {}
  // Activation States
  const [activationConfirmationOpen, setActivationConfirmationOpen] = useState<boolean>(false)
  // Registration States
  const [isUserSelfRegistration, setIsUserSelfRegistration] = useState<boolean>(schoolUsers?.schoolCode ? true : false)
  const [schoolCode, setSchoolCode] = useState<string>(schoolUsers?.schoolCode ?? '')
  // Schedule States
  const [schoolScheduleGroups, setSchoolScheduleGroups] = useState<ScheduleGroupProps[]>(
    cloneDeep(schoolUsers?.schoolScheduleGroups ?? []),
  )
  const [currentScheduleGroup, setCurrentScheduleGroup] = useState<number>(1)
  // Activity States
  const [schoolActivities, setSchoolActivities] = useState<string[]>(schoolUsers?.schoolActivities?.sort() ?? [])
  // School Admins
  const [schoolAdmins, setSchoolAdmins] = useState<string[]>(schoolUsers?.schoolAdmins ?? [])
  // Billing States
  const isShowBillingSettings: boolean = schoolUsers?.schoolSubscription?.billingCycle === 'TEST' ? false : true
  const [isSchoolSubscription, setIsSchoolSubscription] = useState<boolean>(
    schoolUsers?.schoolSubscription?.billingCycle ? true : false,
  )
  const [schoolSubscription, setSchoolSubscription] = useState<SubscriptionProps>(schoolUsers?.schoolSubscription)
  // Track Unsaved States
  const [isUnsavedRegistrationChanges, setIsUnsavedRegistrationChanges] = useState<boolean>(false)
  const [isUnsavedScheduleGroupsChanges, setIsUnsavedScheduleGroupsChanges] = useState<boolean>(false)
  const [isUnsavedActivitiesChanges, setIsUnsavedActivitiesChanges] = useState<boolean>(false)
  const [isUnsavedAdminsChanges, setIsUnsavedAdminsChanges] = useState<boolean>(false)
  const [isUnsavedSubscriptionChanges, setIsUnsavedSubscriptionChanges] = useState<boolean>(false)
  const [isUnsavedChanges, setIsUnsavedChanges] = useState<boolean>(false)
  // Misceallaneous States
  const [applySettingsConfirmationOpen, setApplySettingsConfirmationOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()

  // Validation Checks
  const isStudents: boolean =
    Array.isArray(students) && students.filter((v) => v.userRelationship !== 'SchoolAdmin').length ? true : false
  const isInvalidSchoolCode: boolean = isUserSelfRegistration && !isValidSchoolCode(schoolCode, true) ? true : false
  const isInvalidScheduleGroupTime: boolean = schoolScheduleGroups.some(
    (v) =>
      !v?.schoolScheduleGroupStartTime ||
      !v?.schoolScheduleGroupEndTime ||
      v.schoolScheduleGroupStartTime > v.schoolScheduleGroupEndTime,
  )
  const isInvalidScheduleGroupName: boolean = schoolScheduleGroups.some((v) => !v?.schoolScheduleGroupName)
  const isInvalidSchoolAdminEmail: boolean = schoolAdmins.some((v) => !isValidEmail(v))
  const isInvalidBillingSettings: boolean = isSchoolSubscription && !schoolSubscription?.billingCycle ? true : false
  const checkIfScheduleGroupTimeInvalid = (i: number): boolean =>
    !schoolScheduleGroups[i]?.schoolScheduleGroupStartTime ||
    !schoolScheduleGroups[i]?.schoolScheduleGroupEndTime ||
    schoolScheduleGroups[i].schoolScheduleGroupStartTime > schoolScheduleGroups[i].schoolScheduleGroupEndTime
  const checkIfScheduleGroupNameInvalid = (i: number): boolean => !schoolScheduleGroups[i]?.schoolScheduleGroupName
  const checkIfSchoolAdminsEmailInvalid = (i: number): boolean => !isValidEmail(schoolAdmins[i])

  // Function to handle Carpool Activation dialog open
  const handleActivationToggle = () => {
    setActivationConfirmationOpen(true)
  }
  const handleActivationConfirmation = async (confirmed: boolean) => {
    setActivationConfirmationOpen(false)
    if (confirmed) {
      setIsLoading(!isCarpoolActive ? 'Enabling service...' : 'Disabling service...')
      // FIXME: Currently, isCarpoolActive is set to true for all schools by default in the backend.
      // Instead, we need to default isCarpoolActive to false in the backend, and enable it only when schools consent to Terms of Service on School Settings page on frontend.
      // HACK: Since schoolCode field shall always be undefined for a new school, we will use this field to assert if a school is really active and configured by school admin or not.
      // Specifically, 1) Frontend will write null schoolCode field when school admins agree to terms & enable school service. 2) Backend will return school is inactive if schoolCode is null
      const { msg, error } = !isCarpoolActive
        ? await updateSchool(schoolName, !isCarpoolActive, undefined, undefined, undefined, null)
        : await updateSchool(schoolName, !isCarpoolActive)
      if (error) setMsg(msg)
      else {
        window.location.reload()
      }
      setIsLoading('')
    }
  }

  // Handle adding a new scheduleGroup
  const handleAddScheduleGroup = () => {
    if (schoolScheduleGroups.length < MAX_SCHEDULE_GROUPS) {
      const newScheduleGroup = {
        schoolScheduleGroupStartTime: 0,
        schoolScheduleGroupEndTime: 0,
        schoolScheduleGroupName: '',
      }
      setSchoolScheduleGroups([...schoolScheduleGroups, newScheduleGroup])
      setCurrentScheduleGroup(schoolScheduleGroups.length + 1)
    }
  }

  // Handle removing the last scheduleGroup
  const handleRemoveScheduleGroup = () => {
    if (schoolScheduleGroups.length > MIN_SCHEDULE_GROUPS) {
      const updatedScheduleGroups = schoolScheduleGroups.slice(0, -1)
      setSchoolScheduleGroups(updatedScheduleGroups)
      setCurrentScheduleGroup(schoolScheduleGroups.length - 1)
    }
  }

  const handleDeleteScheduleGroup = (index: number) => {
    if (!schoolScheduleGroups[schoolScheduleGroups.length - 1]) {
      setMsg({
        style: 'error',
        text: 'Cannot delete scheduleGroup with assigned families.',
      })
    } else {
      // removing elements from the array
      const updatedScheduleGroups = [...schoolScheduleGroups]
      updatedScheduleGroups.splice(index, 1)
      setSchoolScheduleGroups(updatedScheduleGroups)
      setCurrentScheduleGroup(updatedScheduleGroups.length) // Update the currentScheduleGroup count to reflect the updated array length
    }
  }
  // Handling the scheduleGroup name of each index
  const handleScheduleGroupNameChange = (index: number, value: string) => {
    const updatedScheduleGroups = [...schoolScheduleGroups]
    updatedScheduleGroups[index].schoolScheduleGroupName = value
    setSchoolScheduleGroups(updatedScheduleGroups)
  }
  // Handling the scheduleGroup start time of each index
  const handleScheduleGroupStartTimeChange = (index: number, value: number) => {
    const updatedScheduleGroups = [...schoolScheduleGroups]
    updatedScheduleGroups[index].schoolScheduleGroupStartTime = value
    setSchoolScheduleGroups(updatedScheduleGroups)
  }
  // Handling the scheduleGroup end time of each index
  const handleScheduleGroupEndTimeChange = (index: number, value: number) => {
    const updatedScheduleGroups = [...schoolScheduleGroups]
    updatedScheduleGroups[index].schoolScheduleGroupEndTime = value
    setSchoolScheduleGroups(updatedScheduleGroups)
  }
  // Handle adding the admin
  const handleAddAdmin = () => {
    if (schoolAdmins.length < MAX_SCHOOL_ADMINS) {
      setSchoolAdmins([...schoolAdmins, ''])
    }
  }
  // Handle removing the any admin
  const handleDeleteAdmin = (index: number) => {
    if (schoolAdmins.length > MIN_SCHOOL_ADMINS) {
      const updatedAdmins = [...schoolAdmins]
      updatedAdmins.splice(index, 1)
      setSchoolAdmins(updatedAdmins)
    }
  }
  // Handling the admin email of each index
  const handleAdminEmailChange = (index: number, value: string) => {
    const updatedAdmins = [...schoolAdmins]
    updatedAdmins[index] = value
    setSchoolAdmins(updatedAdmins)
  }

  // Handling the scheduleGroups stepper
  useEffect(() => {
    // Initialize currentScheduleGroup based on the length of schoolScheduleGroups
    setCurrentScheduleGroup(schoolScheduleGroups.length || 1)
  }, [schoolScheduleGroups])

  // Handling the unsaved changes state
  useEffect(() => {
    if (!isCarpoolActive) setIsUnsavedChanges(false)
    else {
      /* eslint @typescript-eslint/no-var-requires: "off" */
      const isEqual = require('lodash.isequal')
      const dirtyRegistration: boolean =
        isUserSelfRegistration !== (schoolUsers?.schoolCode ? true : false) ||
        (isUserSelfRegistration && schoolCode !== schoolUsers?.schoolCode)
      const dirtyScheduleGroups: boolean = !isEqual(schoolScheduleGroups, schoolUsers?.schoolScheduleGroups)
      const dirtyActivities: boolean =
        JSON.stringify(schoolActivities) !== JSON.stringify(schoolUsers?.schoolActivities?.sort())
      const dirtyAdmins: boolean = !isEqual(schoolAdmins, schoolUsers?.schoolAdmins)
      const dirtySubscription: boolean =
        isSchoolSubscription !== (schoolUsers?.schoolSubscription?.billingCycle ? true : false) ||
        !isEqual(schoolSubscription, schoolUsers?.schoolSubscription)
      const dirty: boolean =
        dirtyRegistration || dirtyScheduleGroups || dirtyAdmins || dirtyActivities || dirtySubscription
      setIsUnsavedRegistrationChanges(dirtyRegistration)
      setIsUnsavedScheduleGroupsChanges(dirtyScheduleGroups)
      setIsUnsavedActivitiesChanges(dirtyActivities)
      setIsUnsavedAdminsChanges(dirtyAdmins)
      setIsUnsavedSubscriptionChanges(dirtySubscription)
      setIsUnsavedChanges(dirty)
    }
  }, [
    isCarpoolActive,
    isUserSelfRegistration,
    schoolCode,
    schoolScheduleGroups,
    schoolActivities,
    schoolAdmins,
    isSchoolSubscription,
    schoolSubscription,
    schoolUsers,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!schoolUsers) return <LinearProgress />

  return (
    <CenterComponent>
      {/* Loading indicator for backend API calls */}
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle>{isLoading}</DialogTitle>
          <CircularProgressWithLabel />
        </Dialog>
      )}

      <Box sx={{ mb: 1 }}>
        <TextComponent size='h5' fontWeight='bold'>{`${titleCaseSchoolNameForUI(schoolName)} Settings`}</TextComponent>
        <TextComponent>{schoolLocation?.address}</TextComponent>
        <SwitchComponent isChecked={isCarpoolActive} onChange={handleActivationToggle}>
          {isCarpoolActive ? (
            'Carpool School Inc. Service Enabled'
          ) : (
            <TextComponent size='h6' color='error'>
              Enable Carpool School Inc. Service
            </TextComponent>
          )}
        </SwitchComponent>
        {/* Delete confirmation dialog box */}
        {isCarpoolActive && isStudents ? (
          <ModalConfirmComponent
            isShow={activationConfirmationOpen}
            title={'Service Disablement Blocked'}
            body={`Remove all registered users before disabling the service. For help, contact support@carpool.school.`}
            actionIcon={'redBlockIcon'}
            onClose={() => handleActivationConfirmation(false)}
          />
        ) : (
          <ModalConfirmComponent
            isShow={activationConfirmationOpen}
            title={!isCarpoolActive ? 'Enable Carpool School Inc. Service?' : 'Disable Carpool School Inc. Service?'}
            body={
              !isCarpoolActive ? (
                <>
                  By clicking below, you confirm that you have the authority to enable the Carpool School Inc. Service
                  for your organization, and you have reviewed and agree to the Carpool School Inc.{' '}
                  <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
                    <NavComponent href={`${baseURL}/home/index.html#tos`} text='Terms of Service' isOpenNewTab={true} />
                  </span>
                  .
                </>
              ) : (
                'This action will prevent families from using the carpool functionality and may affect existing carpool configurations'
              )
            }
            actionIcon={!isCarpoolActive ? 'yellowPowerIcon' : 'redInfoIcon'}
            actionText={!isCarpoolActive ? 'I Agree and Enable Service' : 'Disable'}
            onActionCancel={() => handleActivationConfirmation(false)}
            onActionSubmit={() => handleActivationConfirmation(true)}
          />
        )}
      </Box>

      {/***************** Family Registration Modes *****************/}
      <PaperComponent title={'Family Registration'}>
        <RadioComponent
          row={true}
          radios={[
            {
              disable: !isCarpoolActive,
              isChecked: !isUserSelfRegistration,
              label: (
                <Button color='primary' onClick={() => setIsUserSelfRegistration(false)} sx={{ fontSize: 13 }}>
                  <FileUploadIcon sx={{ fontSize: 20 }} />
                  &nbsp;Admins Upload Family Users
                </Button>
              ),
              data_testid: 'admin_users',
              onChange: (v: any) => setIsUserSelfRegistration(false),
            },
            {
              disable: !isCarpoolActive,
              isChecked: isUserSelfRegistration,
              data_testid: 'user-self-registration',
              label: (
                <Button color='primary' onClick={() => setIsUserSelfRegistration(true)} sx={{ fontSize: 13 }}>
                  <KeyIcon sx={{ fontSize: 20 }} />
                  &nbsp;Family Self-Register with Code
                </Button>
              ),
              onChange: (v: any) => setIsUserSelfRegistration(true),
            },
          ]}
        />
        {isUserSelfRegistration && (
          <InputComponent
            isDisabled={!isCarpoolActive}
            label='Create a secret code for family self registration'
            isRequired={true}
            value={schoolCode}
            isError={isCarpoolActive && !isValidSchoolCode(schoolCode, true)}
            helperText={
              schoolCode
                ? 'Keep code private. Share only with school families. Change code often.'
                : 'Create a secret code to allow families to self-register securely. A unique code prevents unauthorized access to the registration process.'
            }
            onChange={(v: string) => setSchoolCode(v)}
            data_testid='school-secret-code'
          />
        )}
      </PaperComponent>

      {/***************** School Start and Dismissal Times *****************/}
      <PaperComponent
        title={'School Start and Dismissal Times'}
        subtitle={`Would you like to create additional groups to accommodate different start or dismissal times? Admins create groups with distinct names; families choose by name at registration to be matched accordingly.`}
      >
        {/*********DEFAULT SESSION AND FIRST SESSION IN AN ARRAY*********/}
        <Box className='scheduleGroup-stepper'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2px',
              marginBottom: '15px',
            }}
          >
            <Button
              variant='contained'
              color='secondary'
              onClick={handleRemoveScheduleGroup}
              disabled={!isCarpoolActive || schoolScheduleGroups.length === MIN_SCHEDULE_GROUPS}
              sx={{ borderRadius: '50px', minWidth: '20px', padding: '8px', margin: '0 2rem' }}
            >
              <RemoveIcon sx={{ fontSize: '10px' }} />
            </Button>
            <TextComponent size='h5' className='scheduleGroup-count' data_testid='count-testid'>
              {currentScheduleGroup}
            </TextComponent>
            <Button
              variant='contained'
              size='small'
              color='secondary'
              onClick={handleAddScheduleGroup}
              disabled={!isCarpoolActive || schoolScheduleGroups.length >= MAX_SCHEDULE_GROUPS}
              sx={{ borderRadius: '50px', minWidth: '20px', padding: '8px', margin: '0 2rem' }}
            >
              <AddIcon sx={{ fontSize: '10px' }} />
            </Button>
          </Box>
        </Box>
        <Grid container data-testid='schedule-group-row'>
          <Grid item xs={12} sm={3} md={3} lg={3.2} sx={{ mr: 2 }}>
            <InputComponent
              isDisabled={!isCarpoolActive}
              label='School Start Time'
              type='time'
              value={getTimeStringFromNumber(schoolScheduleGroups[0]?.schoolScheduleGroupStartTime)}
              onChange={(v: string) => handleScheduleGroupStartTimeChange(0, getTimeNumberFromString(v))}
              isError={isCarpoolActive && checkIfScheduleGroupTimeInvalid(0)}
              helperText={checkIfScheduleGroupTimeInvalid(0) ? 'Start time must be before end time ' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3.2} sx={{ mr: 2 }}>
            <InputComponent
              isDisabled={!isCarpoolActive}
              label='School End Time'
              type='time'
              value={getTimeStringFromNumber(schoolScheduleGroups[0]?.schoolScheduleGroupEndTime)}
              onChange={(v: string) => handleScheduleGroupEndTimeChange(0, getTimeNumberFromString(v))}
              isError={isCarpoolActive && checkIfScheduleGroupTimeInvalid(0)}
              helperText={checkIfScheduleGroupTimeInvalid(0) ? 'Start time must be before end time ' : ''}
            />
          </Grid>
          {/*********** SHOW SESSION NAME AND DELETE ICON WHEN LENGTH > 1  ****************/}
          {schoolScheduleGroups.length > 1 && (
            <>
              <Grid item xs={12} sm={3} md={3} lg={3.2} sx={{ mr: 2 }}>
                <InputComponent
                  isDisabled={!isCarpoolActive}
                  label={`Name`}
                  value={schoolScheduleGroups[0]?.schoolScheduleGroupName}
                  onChange={(v: string) => handleScheduleGroupNameChange(0, v)}
                  isError={isCarpoolActive && checkIfScheduleGroupNameInvalid(0)}
                  helperText={checkIfScheduleGroupNameInvalid(0) ? 'Every schedule group needs a distinct name' : ''}
                />
              </Grid>
              <IconButton
                disabled={!isCarpoolActive}
                aria-label='delete'
                color='error'
                onClick={() => handleDeleteScheduleGroup(0)}
                sx={{
                  padding: 2,
                  '@media (max-width: 600px)': {
                    margin: '0 auto',
                  },
                }}
                title='Delete Schedule'
              >
                <DeleteOutlinedIcon sx={{ fontSize: 22 }} />
              </IconButton>
            </>
          )}
          {/*********HANDLING OTHER ELEMENTS OF SCHOOL SESSION ARRAY*********/}
          {schoolScheduleGroups.slice(1).map((scheduleGroup, index) => (
            <Grid container key={index}>
              <Grid item xs={12} sm={3} md={3} lg={3.2} sx={{ mr: 2 }}>
                {/*********SCHOOL START TIME*********/}
                <InputComponent
                  isDisabled={!isCarpoolActive}
                  label='School Start Time'
                  type='time'
                  value={getTimeStringFromNumber(scheduleGroup.schoolScheduleGroupStartTime)}
                  onChange={(v: string) => handleScheduleGroupStartTimeChange(index + 1, getTimeNumberFromString(v))}
                  isError={isCarpoolActive && checkIfScheduleGroupTimeInvalid(index + 1)}
                  helperText={checkIfScheduleGroupTimeInvalid(index + 1) ? 'Start time must be before end time ' : ''}
                />
              </Grid>
              {/*********SCHOOL END TIME*********/}
              <Grid item xs={12} sm={3} md={3} lg={3.2} sx={{ mr: 2 }}>
                <InputComponent
                  isDisabled={!isCarpoolActive}
                  label='School End Time'
                  type='time'
                  value={getTimeStringFromNumber(scheduleGroup.schoolScheduleGroupEndTime)}
                  onChange={(v: string) => handleScheduleGroupEndTimeChange(index + 1, getTimeNumberFromString(v))}
                  isError={isCarpoolActive && checkIfScheduleGroupTimeInvalid(index + 1)}
                  helperText={checkIfScheduleGroupTimeInvalid(index + 1) ? 'Start time must be before end time ' : ''}
                />
              </Grid>
              {/*********SESSION NAME*********/}
              <Grid item xs={12} sm={3} md={3} lg={3.2} sx={{ mr: 2 }}>
                <InputComponent
                  isDisabled={!isCarpoolActive}
                  label='Name'
                  value={scheduleGroup.schoolScheduleGroupName}
                  onChange={(v: string) => handleScheduleGroupNameChange(index + 1, v)}
                  isError={isCarpoolActive && checkIfScheduleGroupNameInvalid(index + 1)}
                  helperText={
                    checkIfScheduleGroupNameInvalid(index + 1) ? 'Every schedule group needs a distinct name' : ''
                  }
                />
              </Grid>
              {/*********DELETE SESSION BUTTON*********/}
              <IconButton
                disabled={!isCarpoolActive}
                aria-label='delete'
                color='error'
                onClick={() => handleDeleteScheduleGroup(index + 1)}
                sx={{
                  padding: 2,
                  '@media (max-width: 600px)': {
                    margin: '0 auto',
                  },
                }}
                title='Delete Schedule'
              >
                <DeleteOutlinedIcon sx={{ fontSize: 22 }} />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </PaperComponent>

      {/***************** After School Activities *****************/}
      <PaperComponent
        title={'After School Activities'}
        subtitle={`Admins curate activities. Families select activities. We connect families with matching activities.`}
      >
        <TagComponent
          isDisabled={!isCarpoolActive}
          placeholder='Enter a new activity and press Enter'
          helperText='Select from the listed activities or create your own by typing and pressing Enter'
          options={schoolActivities ?? []}
          selected={schoolActivities ?? []}
          allowMultiple={true}
          allowNew={true}
          newPrefix={`Create this activity: `}
          onChange={(va: any) => setSchoolActivities(va.map((v: any) => v?.label ?? v)?.sort())}
        />
      </PaperComponent>

      {/*************School Admins**********/}
      <PaperComponent
        title={
          <Box display='flex' flexDirection='row' gap={0} p={0}>
            <TextComponent align='left' size='h6' fontWeight='bold'>
              School Admins
            </TextComponent>
            <Button
              disabled={!isCarpoolActive || schoolAdmins.length >= MAX_SCHOOL_ADMINS}
              variant='contained'
              size='small'
              color='secondary'
              onClick={handleAddAdmin}
              sx={{ borderRadius: '50px', minWidth: '20px', padding: '10px', margin: '0 0.5rem' }}
            >
              <AddIcon sx={{ fontSize: '10px' }} data-testid='AddAdminIcon' />
            </Button>
          </Box>
        }
      >
        <div data-testid='school-admins'>
          {schoolAdmins.map((admin, index) => (
            <Grid container key={index}>
              {/******ADMIN EMAIL******/}
              <Grid item xs sx={{ mr: 2 }}>
                <InputComponent
                  isDisabled={!isCarpoolActive}
                  label='Admin Email'
                  value={admin}
                  type='email'
                  onChange={(v: string) => handleAdminEmailChange(index, v)}
                  isError={isCarpoolActive && checkIfSchoolAdminsEmailInvalid(index)}
                  helperText={checkIfSchoolAdminsEmailInvalid(index) ? 'Enter a valid email address ' : ''}
                />
              </Grid>
              {schoolAdmins.length !== MIN_SCHOOL_ADMINS && (
                <IconButton
                  disabled={!isCarpoolActive}
                  aria-label='delete'
                  color='error'
                  onClick={() => handleDeleteAdmin(index)}
                  sx={{ padding: 2 }}
                  title='Delete Admin'
                >
                  <DeleteOutlinedIcon sx={{ fontSize: 22 }} data-testid='DeleteAdminIcon' />
                </IconButton>
              )}
            </Grid>
          ))}
        </div>
      </PaperComponent>

      {/***************** Billing Settings *****************/}
      {isCarpoolActive && isShowBillingSettings && (
        <PaperComponent
          title={`Billing Settings`}
          subtitle={`By default, families subscribe individually. Schools can optionally pay for families.`}
        >
          <SwitchComponent
            isChecked={isSchoolSubscription}
            onChange={() => {
              // School subscription toggled
              setIsSchoolSubscription(!isSchoolSubscription)
              // If toggled to off, also clear school subscription state, else set it to initial state
              if (!isSchoolSubscription === false) setSchoolSubscription({ ...schoolSubscription, billingCycle: '' })
              else setSchoolSubscription(schoolUsers?.schoolSubscription)
            }}
          >
            {schoolSubscriptionLabel(isSchoolSubscription)}
          </SwitchComponent>
          {isSchoolSubscription && (
            <>
              {isBillingDisabledForSchool ? (
                <BillingDisabledComponent
                  body={`Online subscription management is currently in development. For the time being, you can enjoy our carpool services
                free of charge.`}
                  onSubmit={(v: SubscriptionProps) =>
                    setSchoolSubscription({ ...schoolSubscription, billingCycle: 'TRIAL' })
                  }
                />
              ) : (
                <>
                  <BillingMaxLimitComponent
                    maxRegistrations={schoolSubscription?.maxRegistrations}
                    onSubmit={(v: number) => setSchoolSubscription({ ...schoolSubscription, maxRegistrations: v })}
                  />
                  <BillingPaymentComponent
                    name={schoolName}
                    paymentMethod={schoolSubscription?.paymentMethod}
                    onSubmit={(v: string) =>
                      setSchoolSubscription({
                        ...schoolSubscription,
                        paymentMethodId: v,
                        billingCycle: 'MONTHLY',
                      })
                    }
                  />
                </>
              )}
            </>
          )}
        </PaperComponent>
      )}

      {/***************** Update Backend *****************/}
      {isUnsavedChanges && (
        <Box display='flex' flexDirection='column' justifyContent='center' paddingTop={5} gap={2}>
          <ButtonComponent
            text='Submit'
            disable={
              !isCarpoolActive ||
              isInvalidSchoolCode ||
              isInvalidScheduleGroupTime ||
              isInvalidScheduleGroupName ||
              isInvalidSchoolAdminEmail ||
              isInvalidBillingSettings
            }
            onClick={() => setApplySettingsConfirmationOpen(true)}
          />
          <ModalConfirmComponent
            isShow={applySettingsConfirmationOpen}
            title='Submit Changes?'
            body='Review and save your changes to the School Settings'
            actionIcon='yellowSubmitIcon'
            onActionCancel={() => setApplySettingsConfirmationOpen(false)}
            onActionSubmit={async () => {
              setIsLoading('Updating school settings...')
              const { msg, error } = await updateSchool(
                schoolName,
                true,
                isUnsavedScheduleGroupsChanges ? (schoolScheduleGroups ?? []) : undefined,
                isUnsavedAdminsChanges ? (schoolAdmins ?? []) : undefined,
                isUnsavedActivitiesChanges ? (schoolActivities ?? []) : undefined,
                isUnsavedRegistrationChanges ? (isUserSelfRegistration ? schoolCode : null) : undefined,
                isUnsavedSubscriptionChanges ? schoolSubscription : undefined,
              )
              if (error) setMsg(msg)
              else {
                window.location.reload()
              }
              setApplySettingsConfirmationOpen(false)
              setIsLoading('')
            }}
          />
          <ButtonComponent
            text='Cancel'
            variant='outlined'
            color='primary'
            onClick={async () => {
              window.location.reload()
            }}
          />
        </Box>
      )}

      {/***************** Display messages *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}

      {/***************** Contact us *****************/}
      <Box display='flex' flexDirection='column' paddingTop={5}>
        <ButtonComponent
          size='small'
          variant='text'
          color='primary'
          text='Questions? Click here to email us.'
          href='mailto:support@carpool.school'
        />
        <ButtonComponent
          size='small'
          variant='text'
          color='primary'
          text='More questions? Call us!'
          href='tel:+17546667665'
        />
      </Box>
    </CenterComponent>
  )
}

export default Index
