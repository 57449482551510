import { FC } from 'react'

// SVG components
export const DefaultProfileIcon = ({ width, height }: { width?: number; height?: number }) => {
  return (
    <div
      className={`flex items-center justify-center w-${width ?? 14} h-${height ?? 14} rounded-full border  border-gray-200 bg-gray-100`}
    >
      <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'>
        <path
          d='M13.9998 16.3327C17.2215 16.3327 19.8332 13.721 19.8332 10.4993C19.8332 7.27769 17.2215 4.66602 13.9998 4.66602C10.7782 4.66602 8.1665 7.27769 8.1665 10.4993C8.1665 13.721 10.7782 16.3327 13.9998 16.3327ZM13.9998 16.3327C8.84518 16.3327 4.6665 19.4667 4.6665 23.3327M13.9998 16.3327C19.1545 16.3327 23.3332 19.4667 23.3332 23.3327'
          stroke='#C1C1C1'
          strokeWidth='1.4'
          strokeLinecap='round'
        />
      </svg>
    </div>
  )
}

export const EditProfileIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M0.75 10C0.75 4.89137 4.89137 0.75 10 0.75C15.1086 0.75 19.25 4.89137 19.25 10C19.25 15.1086 15.1086 19.25 10 19.25C4.89137 19.25 0.75 15.1086 0.75 10Z'
      stroke='#A3A3A3'
      strokeWidth='1.5'
    />
    <path
      d='M9.99986 11.3021C11.7977 11.3021 13.2551 9.84472 13.2551 8.04691C13.2551 6.2491 11.7977 4.79169 9.99986 4.79169C8.20205 4.79169 6.74464 6.2491 6.74464 8.04691C6.74464 9.84472 8.20205 11.3021 9.99986 11.3021ZM9.99986 11.3021C7.12337 11.3021 4.7915 13.051 4.7915 15.2084M9.99986 11.3021C12.8764 11.3021 15.2082 13.051 15.2082 15.2084'
      stroke='#A3A3A3'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
)

export const ChangeSchoolIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M3 21H21M4 21V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V21M9 21V13C9 12.2044 9.31607 11.4413 9.87868 10.8787C10.4413 10.3161 11.2044 10 12 10C12.7956 10 13.5587 10.3161 14.1213 10.8787C14.6839 11.4413 15 12.2044 15 13V21'
      stroke='#A3A3A3'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const SettingsIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M10.325 4.317C10.751 2.561 13.249 2.561 13.675 4.317C13.7389 4.5808 13.8642 4.82578 14.0407 5.032C14.2172 5.23822 14.4399 5.39985 14.6907 5.50375C14.9414 5.60764 15.2132 5.65085 15.4838 5.62987C15.7544 5.60889 16.0162 5.5243 16.248 5.383C17.791 4.443 19.558 6.209 18.618 7.753C18.4769 7.98466 18.3924 8.24634 18.3715 8.51677C18.3506 8.78721 18.3938 9.05877 18.4975 9.30938C18.6013 9.55999 18.7627 9.78258 18.9687 9.95905C19.1747 10.1355 19.4194 10.2609 19.683 10.325C21.439 10.751 21.439 13.249 19.683 13.675C19.4192 13.7389 19.1742 13.8642 18.968 14.0407C18.7618 14.2172 18.6001 14.4399 18.4963 14.6907C18.3924 14.9414 18.3491 15.2132 18.3701 15.4838C18.3911 15.7544 18.4757 16.0162 18.617 16.248C19.557 17.791 17.791 19.558 16.247 18.618C16.0153 18.4769 15.7537 18.3924 15.4832 18.3715C15.2128 18.3506 14.9412 18.3938 14.6906 18.4975C14.44 18.6013 14.2174 18.7627 14.0409 18.9687C13.8645 19.1747 13.7391 19.4194 13.675 19.683C13.249 21.439 10.751 21.439 10.325 19.683C10.2611 19.4192 10.1358 19.1742 9.95929 18.968C9.7828 18.7618 9.56011 18.6001 9.30935 18.4963C9.05859 18.3924 8.78683 18.3491 8.51621 18.3701C8.24559 18.3911 7.98375 18.4757 7.752 18.617C6.209 19.557 4.442 17.791 5.382 16.247C5.5231 16.0153 5.60755 15.7537 5.62848 15.4832C5.64942 15.2128 5.60624 14.9412 5.50247 14.6906C5.3987 14.44 5.23726 14.2174 5.03127 14.0409C4.82529 13.8645 4.58056 13.7391 4.317 13.675C2.561 13.249 2.561 10.751 4.317 10.325C4.5808 10.2611 4.82578 10.1358 5.032 9.95929C5.23822 9.7828 5.39985 9.56011 5.50375 9.30935C5.60764 9.05859 5.65085 8.78683 5.62987 8.51621C5.60889 8.24559 5.5243 7.98375 5.383 7.752C4.443 6.209 6.209 4.442 7.753 5.382C8.753 5.99 10.049 5.452 10.325 4.317Z'
      stroke='#A3A3A3'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12Z'
      stroke='#A3A3A3'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const ArrowIcon = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      style={{
        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.4s ease-in-out',
      }}
    >
      <path
        d='M16.775 14.8617L18.25 13.3784L10 5.13672L1.75 13.3867L3.225 14.8617L10 8.08672L16.775 14.8617Z'
        fill='#A3A3A3'
      />
    </svg>
  )
}

// export const SubscriptionIcon = () => (
//   <div className='w-5 h-5 flex justify-center items-center rounded-2xl border border-custom-yellow bg-white text-custom-bg-checkbox text-xl font-semibold'>$</div>
// )

export const ChangePasswordIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      d='M1.66671 13.3333H18.3334V15H1.66671V13.3333ZM2.62504 9.95833L3.33337 8.725L4.04171 9.95833L5.12504 9.33333L4.41671 8.1H5.83337V6.85H4.41671L5.12504 5.625L4.04171 5L3.33337 6.225L2.62504 5L1.54171 5.625L2.25004 6.85H0.833374V8.1H2.25004L1.54171 9.33333L2.62504 9.95833ZM8.20837 9.33333L9.29171 9.95833L10 8.725L10.7084 9.95833L11.7917 9.33333L11.0834 8.1H12.5V6.85H11.0834L11.7917 5.625L10.7084 5L10 6.225L9.29171 5L8.20837 5.625L8.91671 6.85H7.50004V8.1H8.91671L8.20837 9.33333ZM19.1667 6.85H17.75L18.4584 5.625L17.375 5L16.6667 6.225L15.9584 5L14.875 5.625L15.5834 6.85H14.1667V8.1H15.5834L14.875 9.33333L15.9584 9.95833L16.6667 8.725L17.375 9.95833L18.4584 9.33333L17.75 8.1H19.1667V6.85Z'
      fill='#FF7900'
    />
  </svg>
)

// export const NotificationIcon = () => (
//   <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
//     <path
//       d='M10 18.125C10.9167 18.125 11.6667 17.375 11.6667 16.4583H8.33337C8.33337 17.375 9.08337 18.125 10 18.125ZM15 13.125V8.95833C15 6.4 13.6417 4.25833 11.25 3.69167V3.125C11.25 2.43333 10.6917 1.875 10 1.875C9.30837 1.875 8.75004 2.43333 8.75004 3.125V3.69167C6.36671 4.25833 5.00004 6.39167 5.00004 8.95833V13.125L3.33337 14.7917V15.625H16.6667V14.7917L15 13.125ZM13.3334 13.9583H6.66671V8.95833C6.66671 6.89167 7.92504 5.20833 10 5.20833C12.075 5.20833 13.3334 6.89167 13.3334 8.95833V13.9583Z'
//       fill='#7E00FC'
//     />
//   </svg>
// )

export const TermsAndConditionsIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      d='M18.3333 6.27904H10.8333V7.9457H18.3333V6.27904ZM18.3333 12.9457H10.8333V14.6124H18.3333V12.9457ZM4.61663 9.61237L1.66663 6.66237L2.84163 5.48737L4.60829 7.25404L8.14163 3.7207L9.31663 4.8957L4.61663 9.61237ZM4.61663 16.279L1.66663 13.329L2.84163 12.154L4.60829 13.9207L8.14163 10.3874L9.31663 11.5624L4.61663 16.279Z'
      fill='#008CF0'
    />
  </svg>
)

export const DeleteAccountIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      d='M13.3333 7.5V15.8333H6.66663V7.5H13.3333ZM12.0833 2.5H7.91663L7.08329 3.33333H4.16663V5H15.8333V3.33333H12.9166L12.0833 2.5ZM15 5.83333H4.99996V15.8333C4.99996 16.75 5.74996 17.5 6.66663 17.5H13.3333C14.25 17.5 15 16.75 15 15.8333V5.83333Z'
      fill='#F00013'
    />
  </svg>
)

export const LogOutIcon: FC = () => (
  <svg
    fill='#bdbcbc'
    height='20px'
    width='20px'
    version='1.1'
    id='Capa_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 471 471'
    xmlSpace='preserve'
    stroke='#bdbcbc'
  >
    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
    <g id='SVGRepo_iconCarrier'>
      <g>
        <g>
          <path d='M227.619,444.2h-122.9c-33.4,0-60.5-27.2-60.5-60.5V87.5c0-33.4,27.2-60.5,60.5-60.5h124.9c7.5,0,13.5-6,13.5-13.5 s-6-13.5-13.5-13.5h-124.9c-48.3,0-87.5,39.3-87.5,87.5v296.2c0,48.3,39.3,87.5,87.5,87.5h122.9c7.5,0,13.5-6,13.5-13.5 S235.019,444.2,227.619,444.2z'></path>
          <path d='M450.019,226.1l-85.8-85.8c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1l62.8,62.8h-273.9c-7.5,0-13.5,6-13.5,13.5 s6,13.5,13.5,13.5h273.9l-62.8,62.8c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.8-85.8 C455.319,239.9,455.319,231.3,450.019,226.1z'></path>
        </g>
      </g>
    </g>
  </svg>
)

// Dashboard, Morning, Afternoon, User Card Icons
export const ClearIcon: FC<{ onClick: () => void }> = ({ onClick }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <path
      d='M15.8333 5.3415L14.6583 4.1665L9.99996 8.82484L5.34163 4.1665L4.16663 5.3415L8.82496 9.99984L4.16663 14.6582L5.34163 15.8332L9.99996 11.1748L14.6583 15.8332L15.8333 14.6582L11.175 9.99984L15.8333 5.3415Z'
      fill='#A3A3A3'
    />
  </svg>
)

export const FilterIcon: FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      d='M5.83714 5.00065H14.1705L9.99548 10.2507L5.83714 5.00065ZM3.54548 4.67565C5.22881 6.83398 8.33714 10.834 8.33714 10.834V15.834C8.33714 16.2923 8.71214 16.6673 9.17048 16.6673H10.8371C11.2955 16.6673 11.6705 16.2923 11.6705 15.834V10.834C11.6705 10.834 14.7705 6.83398 16.4538 4.67565C16.8788 4.12565 16.4871 3.33398 15.7955 3.33398H4.20381C3.51214 3.33398 3.12048 4.12565 3.54548 4.67565Z'
      fill='#666666'
    />
  </svg>
)

export const ArrowFilledIcon: FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    role='img'
    aria-label='More options'
  >
    <path
      d='M9.99992 3.33301L8.82492 4.50801L13.4749 9.16634H3.33325V10.833H13.4749L8.82492 15.4913L9.99992 16.6663L16.6666 9.99967L9.99992 3.33301Z'
      fill='#474747'
    />
  </svg>
)

export const CalendarDateEditIcon: FC<{ color: string }> = ({ color }) => (
  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.88694 12.6161C3.92266 12.6161 3.95837 12.6125 3.99409 12.6071L6.99766 12.0804C7.03337 12.0732 7.0673 12.0571 7.0923 12.0304L14.6619 4.46071C14.6785 4.44419 14.6916 4.42457 14.7006 4.40297C14.7096 4.38137 14.7142 4.35821 14.7142 4.33482C14.7142 4.31143 14.7096 4.28828 14.7006 4.26667C14.6916 4.24507 14.6785 4.22545 14.6619 4.20893L11.6941 1.23929C11.6602 1.20536 11.6155 1.1875 11.5673 1.1875C11.5191 1.1875 11.4744 1.20536 11.4405 1.23929L3.87087 8.80893C3.84408 8.83571 3.82801 8.86786 3.82087 8.90357L3.29409 11.9071C3.27671 12.0028 3.28292 12.1013 3.31217 12.194C3.34142 12.2867 3.39283 12.3709 3.46194 12.4393C3.5798 12.5536 3.72801 12.6161 3.88694 12.6161ZM5.09051 9.50179L11.5673 3.02679L12.8762 4.33571L6.39944 10.8107L4.81194 11.0911L5.09051 9.50179ZM14.9994 14.1161H1.85658C1.54051 14.1161 1.28516 14.3714 1.28516 14.6875V15.3304C1.28516 15.4089 1.34944 15.4732 1.42801 15.4732H15.428C15.5066 15.4732 15.5709 15.4089 15.5709 15.3304V14.6875C15.5709 14.3714 15.3155 14.1161 14.9994 14.1161Z'
      fill={color}
    />
  </svg>
)

export const CloseIcon: FC = () => (
  <svg width='44' height='45' viewBox='0 0 44 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 9.38889C0 4.47969 3.97969 0.5 8.88889 0.5H35.1111C40.0203 0.5 44 4.47969 44 9.38889V35.6111C44 40.5203 40.0203 44.5 35.1111 44.5H8.88889C3.97969 44.5 0 40.5203 0 35.6111V9.38889Z'
      fill='#F3F3F3'
    />
    <path
      d='M27.8332 17.841L26.6582 16.666L21.9998 21.3243L17.3415 16.666L16.1665 17.841L20.8248 22.4993L16.1665 27.1577L17.3415 28.3327L21.9998 23.6743L26.6582 28.3327L27.8332 27.1577L23.1748 22.4993L27.8332 17.841Z'
      fill='#474747'
    />
  </svg>
)

export const BackIcon: FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 16 16' fill='none'>
    <path d='M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z' fill='#68727D' />
  </svg>
)

export const NotifyIcon: FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      d='M11.6665 11.666V14.166H4.99984V8.33268C4.99984 6.49102 6.4915 4.99935 8.33317 4.99935C9.0415 4.99935 9.69984 5.21602 10.2332 5.59935L11.4248 4.40768C10.8915 3.98268 10.2665 3.67435 9.58317 3.49935V2.91602C9.58317 2.22435 9.02484 1.66602 8.33317 1.66602C7.6415 1.66602 7.08317 2.22435 7.08317 2.91602V3.49935C4.92484 4.04935 3.33317 6.00768 3.33317 8.33268V14.166H1.6665V15.8327H14.9998V14.166H13.3332V11.666H11.6665ZM8.33317 18.3327C9.24984 18.3327 9.99984 17.5827 9.99984 16.666H6.6665C6.6665 17.5827 7.4165 18.3327 8.33317 18.3327ZM18.3332 6.66602H15.8332V4.16602H14.1665V6.66602H11.6665V8.33268H14.1665V10.8327H15.8332V8.33268H18.3332V6.66602Z'
      fill='#666666'
    />
  </svg>
)

export const ScheduleGroupIcon: FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
    <path
      d='M15 2.25H14.25V0.75H12.75V2.25H5.25V0.75H3.75V2.25H3C2.175 2.25 1.5 2.925 1.5 3.75V15.75C1.5 16.575 2.175 17.25 3 17.25H15C15.825 17.25 16.5 16.575 16.5 15.75V3.75C16.5 2.925 15.825 2.25 15 2.25ZM15 15.75H3V7.5H15V15.75ZM15 6H3V3.75H15V6Z'
      fill='#E5C265'
    />
  </svg>
)

export const InfoIcon: FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      d='M9.1665 5.83268H10.8332V7.49935H9.1665V5.83268ZM9.1665 9.16602H10.8332V14.166H9.1665V9.16602ZM9.99984 1.66602C5.39984 1.66602 1.6665 5.39935 1.6665 9.99935C1.6665 14.5993 5.39984 18.3327 9.99984 18.3327C14.5998 18.3327 18.3332 14.5993 18.3332 9.99935C18.3332 5.39935 14.5998 1.66602 9.99984 1.66602ZM9.99984 16.666C6.32484 16.666 3.33317 13.6743 3.33317 9.99935C3.33317 6.32435 6.32484 3.33268 9.99984 3.33268C13.6748 3.33268 16.6665 6.32435 16.6665 9.99935C16.6665 13.6743 13.6748 16.666 9.99984 16.666Z'
      fill='#666666'
    />
  </svg>
)

export const PhoneIcon: FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M6.54 5C6.6 5.89 6.75 6.76 6.99 7.59L5.79 8.79C5.38 7.59 5.12 6.32 5.03 5H6.54ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.33 14.9 16.22 14.89 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3Z'
      fill='#474747'
    />
  </svg>
)
