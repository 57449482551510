import { BackIcon } from 'components/icons'
import React from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

interface BackComponentProps {
  text?: string
  onClick?: () => void
}

const BackComponent = (props: BackComponentProps): JSX.Element => {
  const navigate: NavigateFunction = useNavigate()
  const { text, onClick } = props ?? {}

  const handleBackClick = () => {
    if (onClick) onClick()
    else navigate(-1)
  }

  return (
    <div className='flex p-4 pl-5 pr-5 gap-3 items-center'>
      <div
        className='flex items-center gap-2.5 cursor-pointer'
        onClick={handleBackClick}
        data-testid='back-button-testid'
      >
        <BackIcon />
      </div>
      <div className='text-[color:var(--Color-Tokens-Text-Modes-Base-Second,#68727D)] text-xl font-semibold leading-[24px]'>
        {text}
      </div>
    </div>
  )
}

export default BackComponent
