import AvatarComponent from 'components/avatar'
import TimeIcon from '../../images/access_time.svg'
import LineIcon from '../../images/Rectangle 18.svg'

interface UserCardMobileComponentProps {
  isAMPool: boolean
  isLast: boolean
  userName: string
  userRelationship: string
  scheduleName?: string
  distance: string
  seatsAvailable?: number
  time?: string
  activities?: string[]
  comments?: string
  action: React.JSX.Element
}

const CardUserMobileComponent = (props: UserCardMobileComponentProps): JSX.Element => {
  const { isAMPool, isLast, userName, userRelationship, scheduleName, distance, time, activities, comments, action } =
    props ?? {}
  // Split activities and handle "more" logic
  const activityList = Array.isArray(activities) ? activities : []
  const maxVisibleActivities = 2 // Number of activities to show before the "+ more" text
  const visibleActivities = activityList.slice(0, maxVisibleActivities)
  const additionalActivitiesCount = activityList.length - maxVisibleActivities

  return (
    <div
      className={`flex flex-col items-start gap-3.5 pt-3.5 ${
        activities || isAMPool ? 'pb-3.5' : 'pb-1'
      } px-1 lg:px-3 self-stretch lg:border lg:rounded-xl   ${!isLast ? 'border-b' : ''}`}
    >
      <div className='flex justify-between items-center self-stretch'>
        <div className='flex items-center gap-2 flex-1'>
          <div className='flex w-10 h-10 p-1.5 justify-center items-center rounded-full '>
            <AvatarComponent name={userName}></AvatarComponent>
          </div>
          <div className='flex flex-col'>
            <div className='flex items-center gap-1'>
              <span className='text-[#474747] text-sm font-medium'>{userName}</span>
              <span className='w-[1px] bg-gray-300 h-[20px]' />
              <span className='text-[#797979] text-xs font-medium'>{userRelationship}</span>
            </div>
            <span className='text-[#FF7900] text-sm font-normal'>{distance} away</span>
          </div>
        </div>
        {action}
      </div>
      <div className='flex flex-col items-start gap-3 self-stretch'>
        {/* Line 1 - Comment  */}
        {comments && (
          <div className='flex items-start gap-1 self-stretch'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              role='img'
              aria-label='Message'
            >
              <path
                d='M16.6667 14.3087L15.6917 13.3337H3.33341V3.33366H16.6667V14.3087ZM16.6667 1.66699H3.33341C2.41675 1.66699 1.66675 2.41699 1.66675 3.33366V13.3337C1.66675 14.2503 2.41675 15.0003 3.33341 15.0003H15.0001L18.3334 18.3337V3.33366C18.3334 2.41699 17.5834 1.66699 16.6667 1.66699Z'
                fill='#A3A3A3'
              />
            </svg>
            <p className='text-[#797979] text-sm font-normal truncate'>{comments}</p>
          </div>
        )}
        {/* Line 2 - Time | Schedule  */}
        <div className='flex items-center gap-1.5'>
          {time && (
            <div className='flex items-center gap-1'>
              <>
                <img src={TimeIcon} alt='' />
                <span className='text-headings text-sm font-normal'>{time}</span>
              </>
            </div>
          )}
          {time && scheduleName && <img src={LineIcon} alt='' />}
          {scheduleName && (
            <>
              {' '}
              <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
                <path
                  d='M15 2.25H14.25V0.75H12.75V2.25H5.25V0.75H3.75V2.25H3C2.175 2.25 1.5 2.925 1.5 3.75V15.75C1.5 16.575 2.175 17.25 3 17.25H15C15.825 17.25 16.5 16.575 16.5 15.75V3.75C16.5 2.925 15.825 2.25 15 2.25ZM15 15.75H3V7.5H15V15.75ZM15 6H3V3.75H15V6Z'
                  fill='#E5C265'
                />
              </svg>{' '}
              <span className='text-headings text-sm font-normal' data-testid='schedule-testid'>
                {scheduleName}
              </span>
            </>
          )}
        </div>
        {/* Line 3 - Activities  */}
        <div
          className={`flex items-center gap-1 text-sub-headings font-normal text-sm ${isAMPool ? 'hidden' : ''}`}
          data-testid='filter-testid'
        >
          {visibleActivities.map((activity: any, index: number) => (
            <div key={index} className='flex items-center gap-1'>
              <div>{activity}</div>
              {index < visibleActivities.length - 1 && (
                <div style={{ background: '#666', width: '5px', height: '5px', borderRadius: '50%' }}></div>
              )}
            </div>
          ))}
          {additionalActivitiesCount > 0 && (
            <div className='flex items-center gap-1'>
              <div>+ {additionalActivitiesCount} more</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CardUserMobileComponent
