import ModalConfirmRemoveComponent from 'components/modal-confirm-remove'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import React from 'react'
import TimeIcon from '../../images/access_time.svg'
import EditIcon from '../../images/edit.svg'
import LocationOn from '../../images/location_on.svg'
import LineIcon from '../../images/Rectangle 18.svg'
import './index.css'
dayjs.extend(customParseFormat)

interface AddressCardComponentProps {
  isAMPool: boolean
  address: string
  seatsAvailable?: number
  date?: string
  time?: string
  activities?: string[]
  onEdit: () => void
  onDelete: () => Promise<void>
}

const AddressCardComponent: React.FC<AddressCardComponentProps> = ({
  isAMPool,
  address,
  date,
  time,
  activities,
  onEdit,
  onDelete,
}) => {
  // Split activities and handle "more" logic
  const activityList = Array.isArray(activities) ? activities : []
  const maxVisibleActivities = 2 // Number of activities to show before the "+ more" text
  const visibleActivities = activityList.slice(0, maxVisibleActivities)
  const additionalActivitiesCount = activityList.length - maxVisibleActivities

  return (
    <>
      <div
        className='rounded-xl p-3.5 lg:p-5 bg-light-yellow border-custom-yellow border '
        data-testid='address-card-testid'
      >
        <div className='flex justify-between items-center font-normal mb-1 pb-0'>
          <p className='text-disable text-sm truncate flex items-center'>
            <img src={LocationOn} alt='Location Icon' />
            <span className='text-headings ml-1 mr-1'>{isAMPool ? 'Pick up' : 'Drop off'}:</span>
            <span className='address-text'>{address}</span>
          </p>
          <div className='flex items-center'>
            <button data-testid='carpool-edit-testid' className='border-none editIcon' onClick={onEdit}>
              <img src={EditIcon} alt='Edit Icon' width='25px' />
            </button>
            <ModalConfirmRemoveComponent
              data_testid={'DeleteCarpoolIcon'}
              title={isAMPool ? 'Remove Morning Carpool?' : 'Remove Afternoon Carpool?'}
              body={
                isAMPool
                  ? 'You will no longer see morning carpool requests'
                  : 'You will no longer see afternoon carpool requests'
              }
              onClick={onDelete}
            />
          </div>
        </div>
        {date && (
          <div className='flex items-center gap-2.5'>
            <div className='flex items-center gap-1'>
              <img src={TimeIcon} alt='Time Icon' />
              <span className='text-headings text-sm font-normal'>{dayjs(date).format('ddd, MMM D')}</span>
              <img src={LineIcon} alt='Line Icon' />
              <span className='text-headings text-sm font-normal'>{time ? time : `You haven't scheduled carpool`}</span>
            </div>
          </div>
        )}
        {activities && (
          <div className={`flex items-center gap-1 text-sub-headings font-normal text-sm mt-2`}>
            {visibleActivities.map((activity: any, index: number) => (
              <div key={index} className='flex items-center gap-1'>
                <div>{activity}</div>
                {index < visibleActivities.length - 1 && (
                  <div style={{ background: '#666', width: '5px', height: '5px', borderRadius: '50%' }}></div>
                )}
              </div>
            ))}
            {additionalActivitiesCount > 0 && (
              <div className='flex items-center gap-1'>
                <div>+ {additionalActivitiesCount} more</div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default AddressCardComponent
